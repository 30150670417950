import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '@configs/utilities'
import SectionWrapperFlexible from '@components/ui/wrappers/SectionWrapperFlexible'
import HeroCard from '@components/pages/home/heroCard/HeroCard'
import capterraImage from '@assets/images/home/heroCards/capterra.webp'
import forbesAdvisorImage from '@assets/images/home/heroCards/forbes-advisor.webp'
import g2Image from '@assets/images/home/heroCards/g2.webp'

const RatingSection = ({ sectionPaddingSm, sectionPaddingMd, sectionPaddingXl }) => {
  return (
    <SectionWrapperFlexible
      sectionPaddingSm={sectionPaddingSm}
      sectionPaddingMd={sectionPaddingMd}
      sectionPaddingXl={sectionPaddingXl}
    >
      <Wrapper>
        <a href="https://www.capterra.com/p/204342/IdeaBuddy/reviews/" target={'_blank'} rel="noreferrer">
          <HeroCard image={capterraImage} alt={'capterra'} />
        </a>
        <a
          href="https://www.forbes.com/advisor/business/software/best-business-plan-software/"
          target={'_blank'}
          rel="noreferrer"
        >
          <HeroCard image={forbesAdvisorImage} alt={'forbes-advisor'} smWidth={200} mdWidth={220} />
        </a>
        <a href="https://www.g2.com/products/ideabuddy/reviews" target={'_blank'} rel="noreferrer">
          <HeroCard image={g2Image} alt={'g2'} />
        </a>
      </Wrapper>
    </SectionWrapperFlexible>
  )
}

export default RatingSection

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: space-between;
  max-width: 898px;
  margin: 0 auto;
  padding-bottom: 15px;

  @media screen and (min-width: ${breakpoint.sm}) {
    align-items: center;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
    justify-content: space-around;
  }
`
