import React from 'react'
import { GlobalPadding } from './Wrappers'
import { breakpoint } from '@configs/utilities'
import styled from 'styled-components'

const SectionWrapperFlexible = ({ children, sectionPaddingSm, sectionPaddingMd, sectionPaddingXl }) => {
  return (
    <Wrapper
      sectionPaddingSm={sectionPaddingSm}
      sectionPaddingMd={sectionPaddingMd}
      sectionPaddingXl={sectionPaddingXl}
    >
      <GlobalPadding>{children}</GlobalPadding>
    </Wrapper>
  )
}

export default SectionWrapperFlexible

const Wrapper = styled.div`
  padding: ${(props) => (props.sectionPaddingSm ? props.sectionPaddingSm : '20px 0 20px 0')};

  @media screen and (min-width: ${breakpoint.md}) {
    padding: ${(props) => (props.sectionPaddingMd ? props.sectionPaddingMd : '40px 0 40px 0')};
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    padding: ${(props) => (props.sectionPaddingXl ? props.sectionPaddingXl : '60px 0 60px 0')};
  }
`
