import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ModalVideo from 'react-modal-video'
import { color, breakpoint, transition, opacity } from '@configs/utilities'
import play from '@assets/images/common/play.svg'
import getGlobalDocument from '@configs/getGlobalDocument'

const ButtonVideo = ({ videoId, openModal, buttonColor }) => {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    let documentStyle = getGlobalDocument().getElementsByTagName('html')[0]

    isOpen ? (documentStyle.style.overflow = 'hidden') : (documentStyle.style.overflow = 'auto')
  }, [isOpen])

  useEffect(() => {
    if (openModal) {
      setOpen(true)
    }
  }, [openModal])

  return (
    <Wrapper>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={videoId}
        muted={true}
        onClose={() => setOpen(false)}
      />
      <BtnLink onClick={() => setOpen(true)} className={buttonColor ? `${buttonColor}` : 'blue'}>
        <img src={play} className="playImage" alt="play" />
      </BtnLink>
    </Wrapper>
  )
}

export default ButtonVideo

const Wrapper = styled.div`
  .blue {
    background: ${color.blue};
    animation: pulse-blue 1.5s infinite;
  }

  .red {
    background: ${color.red};
    animation: pulse-red 1.5s infinite;
  }

  @keyframes pulse-blue {
    0% {
      box-shadow: 0 0 0 0 rgba(14, 100, 230, 0.2);
      transform: scale(1);
    }
    70% {
      box-shadow: 0 0 0 15px rgba(14, 100, 230, 0.1);
      transform: scale(1);
    }
    100% {
      box-shadow: 0 0 0 15px rgba(14, 100, 230, 0);
      transform: scale(1);
    }
  }

  @keyframes pulse-red {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 120, 117, 0.2);
      transform: scale(1);
    }
    70% {
      box-shadow: 0 0 0 15px rgba(255, 120, 117, 0.1);
      transform: scale(1);
    }
    100% {
      box-shadow: 0 0 0 15px rgba(255, 120, 117, 0);
      transform: scale(1);
    }
  }
`

const BtnLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  box-shadow: 0 4px 32px rgba(14, 100, 230, 0.3);
  cursor: pointer;

  transition: ${transition.default};

  @media (hover: hover) {
    &:hover {
      opacity: ${opacity.default};
    }
  }

  @media screen and (min-width: ${breakpoint.md}) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    width: 86px;
    height: 86px;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    width: 104px;
    height: 104px;
  }

  img {
    width: 32px;
    height: 32px;
    margin: 0 0 2px 3px;

    @media screen and (min-width: ${breakpoint.md}) {
      width: 34px;
      height: 34px;
    }

    @media screen and (min-width: ${breakpoint.lg}) {
      width: 36px;
      height: 36px;
    }

    @media screen and (min-width: ${breakpoint.xl}) {
      width: 40px;
      height: 40px;
    }
  }
`
